import './CallbackBtn.scss'

function CallbackBtn(props) {
	return (
		<button onClick={props.click} id="callback" className="callback">
			{props.label}
		</button>
	)
}

export default CallbackBtn
