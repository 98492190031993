import React from 'react';
import './Video.scss'

function Video(props) {
	return (
		<div className="video-frame">
			<video preload="auto" loop="loop" poster={props.poster} autoPlay={props.autoplay} muted={props.muted}
			       controls={props.controls}>
				<source src={props.link} type="video/mp4"/>
			</video>
		</div>
	)
}

export default Video
