import React from 'react';
import RadioItem from './RadioItem/RadioItem';
import './RadioBtns.scss'

function RadioBtns(props) {
	return (
		<div className="radio-btns">
			<RadioItem id={'radio-callback'} text={'Замовити дзвiнок'} value={'1'} checked={props.select === '1'}
			           onchange={event => props.onchange(event)}/>
			<RadioItem id={'radio-faq'} text={'Задати питання'} value={'2'} checked={props.select === '2'}
			           onchange={event => props.onchange(event)}/>
		</div>
	);
}

export default RadioBtns;
