import React from 'react';
import Line from '../../../components/Line/Line';
import TitleH2 from '../../../components/TitleH2/TitleH2';
import FaqItem from './FaqItem/FaqItem';
import db from '../../../db/main_db.json';
import './FaqList.scss'

function FaqList() {
	return (
		<main id="faq">
			<section className="faq-list">
				<div className="container">
					<TitleH2 text={db.faqList.title}/>
					<Line/>
					<ul>
						{db.faqList.faqItems.map(item => (
							<FaqItem key={item.id} {...item}/>
						)).reverse()}
					</ul>
				</div>
			</section>
		</main>
	)
}

export default FaqList
