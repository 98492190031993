import React from 'react';
import './TitleH1.scss'

const TitleH1 = (props) => {
	return (
		<h1>{props.text}<br/>
			<strong>{props.strong}</strong>
		</h1>
	);
};

export default TitleH1;
