import React from 'react';
import './Director.scss'
import Line from '../../Line/Line';
import TitleH2 from '../../TitleH2/TitleH2';
import db from '../../../db/main_db.json';
import images from '../../../db/images_db.json';
import TitleH3 from '../../TitleH3/TitleH3';
import Picture from '../../Picture/Picture';

const Director = () => {
	return (
		<section className="director">
			<div className="container">
				<div className="wrap">
					<div className="item">
						<TitleH2 text={db.director.title}/>
						<Line/>
					</div>
					<div className="item">
						<TitleH3 text={db.director.title2}/>
						<p>{db.director.text1}</p>
						<p>{db.director.text2}</p>
						<p>{db.director.text3}</p>
					</div>
					<div className="item">
						<Picture src={images.about.director}/>
					</div>
				</div>
			</div>
		</section>
	);
};

export default Director;
