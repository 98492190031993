import React from 'react';
import './LogoColor.scss'
import logoColor from '../Logo/logo-color.png';
import {NavLink} from 'react-router-dom';

const LogoColor = () => {
	return (
		<NavLink className="logo-color" to="/">
			<img src={logoColor} alt="logo color"/>
		</NavLink>
	);
};

export default LogoColor;
