import React from 'react';
import './Picture.scss'

const Picture = (props) => {
	return (
		<div className="picture">
			<img src={props.src} alt={props.alt}/>
		</div>
	);
};

export default Picture;
