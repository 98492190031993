import React from 'react';
import Offer from '../../components/_sections/Offer/Offer';
import Calculation from '../../components/_sections/Calculation/Calculation';
import db from '../../db/main_db.json';
import images from '../../db/images_db.json';
import './Tariffs.scss'

function Tariffs(props) {
	return (
		<main id="tariffs">
			<Offer bg={images.tariffs.offer} title1={db.offerTariffs.title} title2={db.offerTariffs.title2}
			       subtitle={db.offerTariffs.subtitle} btn={db.offerTariffs.btn} click={() => props.setActive(true)}/>
			<Calculation />
		</main>
	)
}

export default Tariffs
