import React, {useEffect, useState} from 'react';
import InputMask from 'react-input-mask';
import CloseBtn from '../CloseBtn/CloseBtn';
import Btn from '../Btns/Btn';
import Picture from '../Picture/Picture';
import TitleH3 from '../TitleH3/TitleH3';
import RadioBtns from '../RadioBtns/RadioBtns';
import LogoColor from '../Logo/logo-color.png';
import db from '../../db/main_db.json';
import './Modal.scss'

const Modal = ({active, setActive}) => {
	const [selectRadio, setSelectRadio] = useState('1');

	const [name, setName] = useState('');
	const [phone, setPhone] = useState('');
	const [text, setText] = useState('');
	const [nameDirty, setNameDirty] = useState(false);
	const [phoneDirty, setPhoneDirty] = useState(false);
	const [nameError, setNameError] = useState('Ім\'я не може бути порожнім');
	const [phoneError, setPhoneError] = useState('Телефон не може бути порожнім');
	const [formValid, setFormValid] = useState(false);

	const handleSelectChangeRadio = event => {
		const valueRadio = event.target.value;
		setSelectRadio(valueRadio);
	};

	useEffect(() => {
		if (nameError || phoneError) {
			setFormValid(false)
		} else {
			setFormValid(true)
		}
	}, [nameError, phoneError])

	const nameHandler = (e) => {
		setName(e.target.value)
		const re = /^[А-я A-z][а-яё a-z]+$/;
		if (!re.test(String(e.target.value).toLowerCase())) {
			setNameError('Некоректне ім\'я')
		} else {
			setNameError('')
		}
	}

	const phoneHandler = (e) => {
		setPhone(e.target.value)
		if (e.target.value.length < 17 || e.target.value.length > 17) {
			setPhoneError('Некорректний телефон')
			if (!e.target.value) {
				setPhoneError('Телефон не може бути порожнім')
			}
		} else {
			setPhoneError('')
		}
	}

	const textHandler = (e) => {
		setText(e.target.value)
	}

	const blurHandler = (e) => {
		switch (e.target.name) {
			case 'username':
				setNameDirty(true)
				break
			case 'phone':
				setPhoneDirty(true)
				break
			default:
				break
		}
	}

	const closeModal = () => {
		setName('')
		setPhone('')
		setText('')
		setActive(false)
	}

	return (
		<div className={active ? 'modal active' : 'modal'}>
			<CloseBtn click={() => setActive(false)}/>
			<div className={active ? 'modal-content active' : 'modal-content'}>
				<form action="">
					<div className="form-header">
						<Picture src={LogoColor}/>
						<RadioBtns select={selectRadio} onchange={handleSelectChangeRadio}/>
					</div>
					<hr/>
					<TitleH3 text={(selectRadio === '1') ? `${db.modal.title1}` : `${db.modal.title2}`}/>
					<label htmlFor="user-name">{db.modal.name}
						{(nameDirty && nameError) && <span style={{color: 'red'}}>{nameError}</span>}
						<input id="user-name" type="text" name="username" onChange={e => nameHandler(e)} value={name}
						       onBlur={e => blurHandler(e)}/>
					</label>
					<label htmlFor="user-phone">{db.modal.tel}
						{(phoneDirty && phoneError) && <span style={{color: 'red'}}>{phoneError}</span>}
						<InputMask mask="+38\ 999 999-99-99" maskChar={null} id="user-phone" type="tel" name="phone"
						           onChange={e => phoneHandler(e)}
						           value={phone} onBlur={e => blurHandler(e)}/>
					</label>
					<label className={(selectRadio === '1') ? 'hide' : 'show'}
					       htmlFor="textarea">{db.modal.message}
						<textarea id="textarea" value={text} onChange={e => textHandler(e)}/>
					</label>

					<Btn className="btn" icon="icon-lightning" link={'#'} label={'Вiдправити'} disabled={!formValid}
					     click={closeModal}/>
				</form>
			</div>
		</div>
	)
}

export default Modal
