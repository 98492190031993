import React from 'react';
import {NavLink} from 'react-router-dom';
import './NavBar.scss'

function NavBar(props) {
	const navItems = [
		{id: 1, url: '/', name: 'Головна', exact: true},
		{id: 2, url: '/about', name: 'Про нас', exact: false},
		{id: 3, url: '/client', name: 'Стати клієнтом', exact: false},
		{id: 4, url: '/tariffs', name: 'Тарифи і ціни', exact: false},
		{id: 5, url: '/faq', name: 'Питання та відповіді', exact: false},
		{id: 6, url: '/low', name: 'Законодавство', exact: false},
		{id: 7, url: '/reports', name: 'Звіти', exact: false},
		{id: 8, url: '/news', name: 'Новини', exact: false},
		{id: 9, url: '/contacts', name: 'Контакти', exact: false}
	]

	return (
		<nav className='container'>
			<ul>
				{navItems.map(item => (
					<li key={item.id} onClick={props.onClick}>
						<NavLink exact={item.exact} to={item.url}>
							{item.name}
						</NavLink>
					</li>
				))}
			</ul>
		</nav>
	)
}

export default NavBar;
