import React from 'react';
import images from '../../../db/images_db.json';
import db from '../../../db/main_db.json';
import Icon from '../../Icon/Icon';
import TitleH2 from '../../TitleH2/TitleH2';
import Picture from '../../Picture/Picture';
import Line from '../../Line/Line';
import MyLink from '../../Btns/MyLink';
import './ChooseSupplier.scss'

const ChooseSupplier = () => {
	return (
		<section className="choose-supplier">
			<div className="container">
				<div className="wrap">
					<div className="lt">

						<Picture src={images.main.supplier} alt={'choose-supplier-bg'}/>

						<div className="desc a-center">
							<Line/>
							<p>{db.supplier.text1}</p>
							<p>{db.supplier.text2}</p>
							<p>{db.supplier.text3}</p>
						</div>
					</div>
					<div className="rt">
						<Icon icon="icon-trident"/>
						<TitleH2 text={db.supplier.title}/>
						<MyLink icon={'icon-lightning'} label={db.supplier.btn} link="/about"/>
					</div>
				</div>
			</div>
		</section>
	);
};

export default ChooseSupplier;
