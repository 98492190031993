import React from 'react';
import Offer from '../../components/_sections/Offer/Offer';
import FaqList from '../../components/_sections/FaqList/FaqList';
import images from '../../db/images_db.json';
import db from '../../db/main_db.json';
import './Faq.scss'

const Faq = (props) => {
	return (
		<main id="faq">
			<Offer bg={images.faq.offer} title1={db.offerFaq.title} title2={db.offerFaq.title2}
			       subtitle={db.offerFaq.subtitle} btn={db.offerFaq.btn} click={() => props.setActive(true)}/>
			<FaqList />
		</main>
	)
}

export default Faq
