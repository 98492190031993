import React from 'react';
import './Btn.scss'

function Btn(props) {
	return (
		<button className="btn" onClick={props.click} disabled={props.disabled}>
			<div className="circle">
				<span className={`icon ${props.icon}`}></span>
			</div>
			<p>{props.label}</p>
		</button>
	)
}

export default Btn
