import React from 'react';
import './TextBox.scss'

const TextBox = (props) => {
	return (
		<div className="text-box">
			<h3>{props.title}</h3>
			<p>{props.text}</p>
		</div>
	);
};

export default TextBox;
