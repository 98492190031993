import React from 'react';
import './Docs.scss'
import db from '../../../db/main_db.json';
import TitleH2 from '../../TitleH2/TitleH2';
import TitleH3 from '../../TitleH3/TitleH3';
import CallbackBtn from '../../CallbackBtn/CallbackBtn';
import LoadLink from '../../Btns/LoadLink';

const Docs = (props) => {
	return (
		<section className="documents">
			<div className="container">
				<TitleH2 text={db.docs.title}/>
				<ul>
					{db.docs.docsList.map(item => (
						<li key={item.id}>
							<TitleH3 text={item.title}/>
							<p className="desc">{item.text}</p>
							<LoadLink label={item.btn} icon={'icon-lightning'} link={item.url}/>
						</li>
					))}
				</ul>
				<CallbackBtn className="callback" click={() => props.setActive(true)} label={db.docs.bigBtn}/>
			</div>
		</section>
	);
};

export default Docs;
