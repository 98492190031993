import React from 'react';
import Offer from '../../components/_sections/Offer/Offer';
import Numbers from '../../components/_sections/Numbers/Numbers';
import ChooseSupplier from '../../components/_sections/ChooseSupplier/ChooseSupplier';
import WhyWe from '../../components/_sections/WhyWe/WhyWe';
import ComfortableOffice from '../../components/_sections/ComfortableOffice/ComfortableOffice';
import NewsSection from '../../components/_sections/NewsSection/NewsSection';
import images from '../../db/images_db.json';
import db from '../../db/main_db.json';

function Main() {
	return (
		<main id="homepage">
			<Offer bg={images.main.offer} title1={db.offer.title} title2={db.offer.title2} btn={db.offer.btn}
			       link={'/client'}/>
			<Numbers/>
			<ChooseSupplier/>
			<WhyWe/>
			<ComfortableOffice/>
			<NewsSection title={db.news.title} reverse={true} splice={(-2)}/>
		</main>
	)
}

export default Main;
