import React from 'react';
import Icon from '../Icon/Icon';

const Number = (props) => {
	return (
		<li>
			<div className="box">
				<span>{props.num}</span>
				<Icon icon="icon-lightning a-center"/>
			</div>
			<p>{props.text}</p>
		</li>
	);
};

export default Number;
