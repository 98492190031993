import React from 'react';
import Offer from '../../components/_sections/Offer/Offer';
import NewsSection from '../../components/_sections/NewsSection/NewsSection';
import images from '../../db/images_db.json';
import db from '../../db/main_db.json';
import './News.scss'

function News() {
	return (
		<main id="news">
			<NewsSection splice={(-1)} reverse={true} title={db.news.title2}/>
			<Offer bg={images.news.offer} title1={db.offer.title} title2={db.offer.title2} btn={db.offer.btn}
			       link={'/client'}/>
			<NewsSection splice={(-1, 1)} reverse={false} title={db.news.title3}/>
		</main>
	)
}

export default News
