import React from 'react';
import Icon from '../../Icon/Icon';
import TitleH2 from '../../TitleH2/TitleH2';
import Btn from '../../Btns/Btn';
import db from '../../../db/main_db.json';
import './Conditions.scss'

const Conditions = (props) => {
	return (
		<section className="conditions">
			<div className="container">
				<ul>
					<li>
						<div className="left">
							<div className="number">
								<Icon icon={'icon-lightning'}/>
							</div>
						</div>

						<div className="arrow-left">
							<Icon icon="icon-arrow-left a-center"/>
						</div>

						<div className="right">
							<div className="wrapper">
								<TitleH2 text={db.conditions.title1}/>
								<p className="desc">{db.conditions.text1}</p>
								<Btn click={() => props.setActive(true)} label={db.conditions.btn} icon={'icon-lightning'}/>
							</div>
						</div>
					</li>
					<li>
						<div className="left">
							<div className="number">
								<Icon icon={'icon-lightning'}/>
							</div>
						</div>

						<div className="arrow-right">
							<Icon icon={'icon-arrow-right a-center'}/>
						</div>

						<div className="right">
							<div className="wrapper">
								<TitleH2 text={db.conditions.title2}/>
								<p className="desc">{db.conditions.text2}</p>
								<Btn click={() => props.setActive(true)} label={db.conditions.btn} icon={'icon-lightning'}/>
							</div>
						</div>
					</li>
					<li>
						<div className="left">
							<div className="number">
								<Icon icon={'icon-lightning'}/>
							</div>
						</div>

						<div className="arrow-left arrow-left2">
							<Icon icon="icon-arrow-left a-center"/>
						</div>

						<div className="right">
							<div className="wrapper">
								<TitleH2 text={db.conditions.title3}/>
								<p className="desc">{db.conditions.text3}</p>
								<Btn click={() => props.setActive(true)} label={db.conditions.btn} icon="icon-lightning"/>
							</div>
						</div>
					</li>
					<li>
						<div className="left">
							<div className="number">
								<Icon icon={'icon-lightning'}/>
							</div>
						</div>
						<div className="right">
							<div className="wrapper">
								<TitleH2 text={db.conditions.title4}/>
								<p className="desc">{db.conditions.text4}</p>
								<Btn click={() => props.setActive(true)} label={db.conditions.btn} icon={'icon-lightning'}/>
							</div>
						</div>
					</li>
				</ul>
			</div>
		</section>
	);
};

export default Conditions;
