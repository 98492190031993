import React from 'react';
import Picture from '../../Picture/Picture';
import TitleH2 from '../../TitleH2/TitleH2';
import images from '../../../db/images_db.json'
import db from '../../../db/main_db.json';
import './Dispute.scss'

const Dispute = () => {
	return (
		<section className="dispute">
			<div className="container">
				<div className="wrap">
					<div className="left">
						<Picture src={images.contacts.dispute}/>
					</div>
					<div className="right">
						<TitleH2 text={db.dispute.title}/>
						<div className="dispute-text">{db.dispute.desc}</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default Dispute;
