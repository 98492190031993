import React from 'react';
import './Btn.scss'

function LoadLink(props) {
	return (
		<a className="btn" href={props.link} target={'_blank'} rel="noreferrer" disabled={props.disabled}>
			<div className="circle">
				<span className={`icon a-center ${props.icon}`} />
			</div>
			<p>{props.label}</p>
		</a>
	);
}

export default LoadLink;
