import React from 'react';
import IconBox from '../../IconBox/IconBox';
import Video from '../../Video/Video';
import TitleH2 from '../../TitleH2/TitleH2';
import MyLink from '../../Btns/MyLink';
import movie from '../../../video/video.mp4';
import images from '../../../db/images_db.json';
import db from '../../../db/main_db.json';
import './ComfortableOffice.scss'

const ComfortableOffice = () => {
	return (
		<section className="comfortable-office">
			<div className="container">
				<div className="wrap">
					<div className="left">
						<TitleH2 text={db.office.title}/>
						<p className="desc">{db.office.desc}</p>
						<div className="wrapper">
							<IconBox text={db.office.text1}/>
							<IconBox text={db.office.text2}/>
							<IconBox text={db.office.text3}/>
							<IconBox text={db.office.text4}/>
						</div>

						<MyLink icon={'icon-lightning'} label={db.office.btn} link="/about"/>

					</div>
					<div className="right">

						<Video link={movie} poster={images.main.offer} controls={true}/>

					</div>
				</div>
			</div>
		</section>
	);
};

export default ComfortableOffice;
