import React from 'react';
import TitleH4 from '../../../components/TitleH4/TitleH4';
import LoadLink from '../../../components/Btns/LoadLink';

const LowItem = (props) => {
	return (
		<li>
			<p className="desc">{props.info}</p>
			<TitleH4 text={props.title}/>
			<LoadLink icon={'icon-lightning'} label={props.btn} link={props.url}/>
		</li>
	);
};

export default LowItem;
