import React from 'react';
import './FooterBox.scss'

const FooterBox = ({children}) => {
	return (
		<div className="footer-box">
			{children}
		</div>
	);
};

export default FooterBox;
