import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import Logo from '../Logo/Logo';
import NavBar from '../NavBar/NavBar';
import BurgerMenu from '../BurgerMenu/BurgerMenu';
import Icon from '../Icon/Icon';
import db from '../../db/main_db.json';
import './Header.scss'

function Header() {
	const [isOpen, setIsOpen] = useState(false)

	const toggleMenu = () => {
		setIsOpen(isOpen => (!isOpen))
	}

	const closeMenu = () => {
		setIsOpen(isOpen => (false))
	}

	return (
		<header id="header">

			<div className="info">
				<div className="container">

					<Logo/>

					<div className="info-links">
						<Link className="tel" to="tel:">
							<p>{db.header.text}</p>
							<span>{db.header.tel}</span>
						</Link>
						<Link className="cabinet" to="/cabinet">
							<Icon icon="icon-user"/>
							<span>{db.header.cabinet}</span>
						</Link>
					</div>

					<BurgerMenu click={toggleMenu} classes={isOpen ? 'active' : ''}/>

				</div>
			</div>

			<div id='navigation' className="navigation">
				<NavBar onClick={null}/>
			</div>

			{isOpen && <div className="navigation">
				<NavBar onClick={closeMenu}/>
			</div>}
		</header>
	)
}

export default Header;
