import React, {useState} from 'react';
import Line from '../../../Line/Line';

const FaqItem = ({question, answer}) => {
	const [showAnswer, setShowAnswer] = useState(false);

	return (
		<li onClick={() => setShowAnswer(!showAnswer)}>
			<div className={showAnswer ? 'question active' : 'question'}>{question}</div>
			{showAnswer &&
			<div className={'answer'}>
				<Line/>
				<p>{answer}</p>
			</div>
			}
		</li>
	);
}

export default FaqItem;
