import React from 'react';
import {Link} from 'react-router-dom';
import logoColor from './logo-color.png';
import './Logo.scss'

function Logo() {
	return (
		<Link className="logo" to="/">
			<i className="icon icon-logo"/>
			<div className="logo-color" style={{display: 'none'}}>
				<img src={logoColor} alt="logo color"/>
			</div>
		</Link>
	)
}

export default Logo
