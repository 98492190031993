import React from 'react';
import './RadioItem.scss'

function RadioItem(props) {
	return (
		<div className="radio-item">
			<input id={props.id} value={props.value} type="radio" checked={props.checked}
			       onChange={props.onchange} name="radio"/>
			<label htmlFor={props.id}>{props.text}</label>
		</div>
	);
}

export default RadioItem;
