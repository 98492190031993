import React from 'react';
import './TitleH3.scss'

const TitleH3 = (props) => {
	return (
		<h3>{props.text}</h3>
	);
};

export default TitleH3;
