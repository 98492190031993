import React from 'react';

function BurgerMenu(props) {
	return (
		<button id="burger" onClick={props.click} className={props.classes} style={{display: 'none'}}>
			<div className="burger-i">
				<span/>
				<span/>
				<span/>
			</div>
		</button>
	)
}

export default BurgerMenu
