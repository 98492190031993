import React from 'react';
import TitleH2 from '../../TitleH2/TitleH2';
import Picture from '../../Picture/Picture';
import Icon from '../../Icon/Icon';
import db from '../../../db/main_db.json';
import images from '../../../db/images_db.json';
import './ForDogovor.scss'

const ForDogovor = () => {
	return (
		<section className="for-dogovor">
			<div className="container">
				<div className="wrap">
					<div className="lt">
						<Picture src={images.client.forDogovor} alt="for dogovor bg"/>
					</div>
					<div className="rt">
						<TitleH2 text={db.forDogovor.title}/>
						<ul>
							{db.forDogovor.list.map(item => (
								<li key={item.id}>
									<div className="pic">
										<Icon icon={'icon-dogovor'}/>
									</div>
									<p>{item.text}</p>
								</li>
							))}
						</ul>
					</div>
				</div>
			</div>
		</section>
	);
};

export default ForDogovor;
