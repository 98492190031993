import React from 'react';
import './TitleH4.scss'

const TitleH4 = (props) => {
	return (
		<h4>{props.text}</h4>
	);
};

export default TitleH4;
