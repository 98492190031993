import React from 'react';
import TitleH3 from '../../TitleH3/TitleH3';
import Icon from '../../Icon/Icon';
import db from '../../../db/main_db.json';
import './Means.scss'

const Means = () => {
	return (
		<section className="means">
			<div className="container">
				<div className="wrap">

					<div className="item">
						<div className="title">
							<TitleH3 text={db.means.title1}/>
							<Icon icon={'icon-phone'}/>
						</div>
						<hr/>
						<div className="info">
							<p>{db.header.text}</p>
							<a href="tel:">{db.header.tel}</a>
						</div>
						<div className="others">
							<p>{db.means.subtitle1}</p>
							<a href="tel:">{db.footer.tel1}</a>
							<a href="tel:">{db.footer.tel2}</a>
							<a href="tel:">{db.footer.tel3}</a>
							<a href="tel:">{db.footer.tel4}</a>
						</div>
					</div>

					<div className="item">
						<div className="title">
							<TitleH3 text={db.means.title2}/>
							<Icon icon={'icon-envelope'}/>
						</div>
						<hr/>
						<div className="email">
							<p>{db.means.subtitle2}</p>
							<a href="mailto:">{db.footer.email}</a>
						</div>
						<div className="address">
							<p>{db.means.subtitle21}</p>
							<a href="/">{db.footer.address}</a>
						</div>
					</div>

					<div className="item">
						<div className="title">
							<TitleH3 text={db.means.title3}/>
							<Icon icon={'icon-markmap'}/>
						</div>
						<hr/>
						<div className="address">
							<p>{db.means.subtitle3}</p>
							<a href="/">{db.footer.address}</a>
						</div>
					</div>

				</div>
			</div>
		</section>
	);
};

export default Means;
