import React from 'react';
import TitleH3 from '../../../TitleH3/TitleH3';
import Picture from '../../../Picture/Picture';
import {Link} from 'react-router-dom';
import './Partner.scss'

const Partner = (props) => {
	return (
		<li key={props.id} className="partner">
			<div className="company">
				<TitleH3 text={props.title}/>
				<Picture src={props.img}/>
			</div>
			<p>{props.text}</p>

			<Link className="email" to="mailto:">{props.email}</Link>
			<Link className="weblink" to="/">{props.site}</Link>
		</li>
	);
};

export default Partner;
