import React from 'react';
import NewsItem from '../../NewsItem/NewsItem';
import TitleH2 from '../../TitleH2/TitleH2';
import Line from '../../Line/Line';
import news from '../../../db/all-news_db.json';
import './NewsSection.scss'

const NewsSection = (props) => {
	return (
		<section className="news">
			<div className="container">
				<TitleH2 text={props.title}/>
				<Line />
				<ul>
					{
						props.reverse ? news.map(item => (
						<NewsItem key={item.id} {...item}/>
						)).splice(props.splice).reverse() :
						news.map(item => (
						<NewsItem key={item.id} {...item}/>
						)).reverse().splice(props.splice)
					}
				</ul>
			</div>
		</section>
	);
};

export default NewsSection;
