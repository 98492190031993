import React from 'react';
import Offer from '../../components/_sections/Offer/Offer';
import WhyWe from '../../components/_sections/WhyWe/WhyWe';
import Director from '../../components/_sections/Director/Director';
import Partners from '../../components/_sections/Partners/Partners';
import images from '../../db/images_db.json';
import db from '../../db/main_db.json';
import './About.scss'

function About() {
	return (
		<main id="about">
			<Offer bg={images.about.offer} title1={db.offerAbout.title} title2={db.offerAbout.title2}
			       subtitle={db.offerAbout.subtitle} btn={db.offerAbout.btn} link={'/client'}/>
			<WhyWe/>
			<Director/>
			<Partners/>
		</main>
	)
}

export default About
