import React, {useEffect, useState} from 'react';
import {BrowserRouter, Redirect, Route, Switch} from 'react-router-dom';
import Header from './components/Header/Header';
import Main from './pages/Main/Main';
import About from './pages/About/About';
import Client from './pages/Client/Client';
import Tariffs from './pages/Tariffs/Tariffs';
import Faq from './pages/Faq/Faq';
import Low from './pages/Low/Low';
import Reports from './pages/Reports/Reports';
import News from './pages/News/News';
import Contacts from './pages/Contacts/Contacts';
import Footer from './components/Footer/Footer';
import UpArrow from './components/UpArrow/UpArrow';
import Modal from './components/Modal/Modal';
import Cabinet from './pages/Cabinet/Cabinet';
import './App.scss';


function App() {
	const [modalActive, setModalActive] = useState(false)

	useEffect(() => {
		const body = document.querySelector('body');
		body.style.overflow = modalActive ? 'hidden' : 'auto';
	}, [modalActive])

	return (
		<div className="App">
			<BrowserRouter>
				<Header/>
				<Switch>
					<Route exact path="/" render={() => <Main/>}/>
					<Route path="/about" render={() => <About/>}/>
					<Route path="/client" render={() => <Client setActive={setModalActive}/>}/>
					<Route path="/tariffs" render={() => <Tariffs setActive={setModalActive}/>}/>
					<Route path="/faq" render={() => <Faq setActive={setModalActive}/>}/>
					<Route path="/low" render={() => <Low/>}/>
					<Route path="/reports" render={() => <Reports/>}/>
					<Route path="/news" render={() => <News/>}/>
					<Route path="/contacts" render={() => <Contacts setActive={setModalActive}/>}/>}/>
					<Route path="/cabinet" render={() => <Cabinet/>}/>
					<Redirect from='/' to='/'/>
				</Switch>
				<Footer setActive={setModalActive}/>
				<UpArrow/>
				<Modal active={modalActive} setActive={setModalActive}/>
			</BrowserRouter>
		</div>
	);
}

export default App;
