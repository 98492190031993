import React, {useState} from 'react';
import Btn from '../Btns/Btn';
import CloseBtn from '../CloseBtn/CloseBtn';
import Line from '../Line/Line';
import Picture from '../Picture/Picture';
import TitleH3 from '../TitleH3/TitleH3';
import './NewsItem.scss'

function NewsItem(props) {
	const [isOpen, setIsOpen] = useState(false)

	const toggleNews = () => {
		setIsOpen(isOpen => (!isOpen))
	}

	const closeNews = () => {
		setIsOpen(isOpen => (false))
	}

	return (
		<li className="news-item">
			<div className="left">
				<Picture src={props.image} alt="news picture"/>
			</div>
			<div className="right">
				<span>{props.date}</span>
				<TitleH3 text={props.title}/>
				<p className="excerpt">{props.excerpt}</p>
				<Btn icon={'icon-lightning'} click={toggleNews} label={props.btn}/>
			</div>
			{isOpen &&
			<div className="overlay a-center">
				<CloseBtn click={closeNews}/>
				<div className="wrap">
					<Line/>
					<p>{props.desc}</p>
				</div>
			</div>
			}
		</li>
	)
}

export default NewsItem
