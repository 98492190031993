import React from 'react';
import TitleH2 from '../../TitleH2/TitleH2';
import Partner from './Partner/Partner';
import db from '../../../db/main_db.json';
import partners from '../../../db/partners_db.json';
import './Partners.scss'

const Partners = () => {
	return (
		<section className="partners">
			<div className="container">
				<div className="title">
					<TitleH2 text={db.partners.title}/>
					<p>{db.partners.subtitle}</p>
				</div>
				<ul>
					{partners.map(item => (
						<Partner key={item.id} title={item.title} img={item.image} text={item.text} email={item.email}
						         site={item.site}/>
					))}
				</ul>
			</div>
		</section>
	);
};

export default Partners;
