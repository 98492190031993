import React from 'react';
import LogoColor from '../LogoColor/LogoColor';
import Icon from '../Icon/Icon';
import CallbackBtn from '../CallbackBtn/CallbackBtn';
import NavBar from '../NavBar/NavBar';
import FooterBox from './FooterBox/FooterBox';
import db from '../../db/main_db.json';
import './Footer.scss'


function Footer(props) {
	return (
		<footer className="footer" id="footer">

			<CallbackBtn click={() => props.setActive(true)} label={db.footer.btn}/>

			<div className="container">
				<div className="wrap">

					<FooterBox>
						<LogoColor/>
						<div className="box">
							<p>{db.footer.title1}</p>
							<ul className="icons">
								<li><a href="/" target="_blank"><Icon icon="icon-insta"/></a></li>
								<li><a href="/" target="_blank"><Icon icon="icon-fb"/></a></li>
								<li><a href="/" target="_blank"><Icon icon="icon-youtube"/></a></li>
							</ul>
						</div>
					</FooterBox>

					<FooterBox>
						<NavBar/>
					</FooterBox>

					<FooterBox>
						<NavBar/>
					</FooterBox>

					<FooterBox>
						<ul>
							<li>
								<Icon icon="icon-phone"/>
								<a href="tel:">
									{db.header.tel}
									<p>{db.header.text}</p>
								</a>
							</li>
							<li>
								<Icon icon="icon-phone"/>
								<a href="tel:">{db.footer.tel1}</a>
							</li>
							<li>
								<Icon icon="icon-phone"/>
								<a href="tel:">{db.footer.tel2}</a>
							</li>
							<li>
								<Icon icon="icon-phone"/>
								<a href="tel:">{db.footer.tel3}</a>
							</li>
							<li>
								<Icon icon="icon-phone"/>
								<a href="tel:">{db.footer.tel4}</a>
							</li>
							<li className="email">
								<Icon icon="icon-envelope"/>
								<a href="mailto:">{db.footer.email}</a>
							</li>
							<li>
								<Icon icon="icon-markmap"/>
								<a href="/">{db.footer.address}</a>
							</li>
						</ul>
					</FooterBox>
				</div>
				<hr/>
				<div className="development">
					<div className="copy">
						<p>{db.footer.copyright}</p>
						<b>|</b>
						<a href="https://adbbox.com" rel="noreferrer" target="_blank">Development by adbbox.com</a>
					</div>
					<div className="agency">
						<span>Design by Rabbitmarketing</span>
					</div>
				</div>
			</div>
		</footer>
	)
}

export default Footer
