import React from 'react';
import Icon from '../Icon/Icon';
import './IconBox.scss'

const IconBox = (props) => {
	return (
		<div className="icon-box">
			<Icon icon="icon-cabinet"/>
			<strong>{props.text}</strong>
		</div>
	);
};

export default IconBox;
