import React from 'react';
import {Link} from 'react-router-dom';
import './Btn.scss'

function MyLink(props) {
	return (
		<Link className="btn" to={props.link} disabled={props.disabled}>
			<div className="circle">
				<span className={`icon a-center ${props.icon}`} />
			</div>
			<p>{props.label}</p>
		</Link>
	);
}

export default MyLink;
