import React from 'react';
import Offer from '../../components/_sections/Offer/Offer';
import ComfortableOffice from '../../components/_sections/ComfortableOffice/ComfortableOffice';
import Conditions from '../../components/_sections/Conditions/Conditions';
import ForDogovor from '../../components/_sections/ForDogovor/ForDogovor';
import Docs from '../../components/_sections/Docs/Docs';
import Example from '../../components/_sections/Example/Example';
import images from '../../db/images_db.json';
import db from '../../db/main_db.json';
import './Client.scss'

function Client(props) {
	return (
		<main id="client">
			<Offer bg={images.client.offer} title1={db.offerClient.title} title2={db.offerClient.title2}
			       subtitle={db.offerClient.subtitle} btn={db.offerClient.btn} click={() => props.setActive(true)}/>
			<Conditions setActive={props.setActive}/>
			<ForDogovor/>
			<Docs setActive={props.setActive}/>
			<Offer bg={images.client.offer2} title1={db.offerClient2.title} title2={db.offerClient2.title2}
			       subtitle={db.offerClient2.subtitle} btn={db.offerClient2.btn} click={() => props.setActive(true)}/>
			<ComfortableOffice/>
			<Example/>
		</main>
	)
}

export default Client
