import React from 'react';
import Icon from '../Icon/Icon';
import './CloseBtn.scss'

function CloseBtn(props) {
	return (
		<button className="btn-close" onClick={props.click}>
			<Icon icon="icon-close"/>
		</button>
	)
}

export default CloseBtn
