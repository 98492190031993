import React from 'react';
import TitleH1 from '../../TitleH1/TitleH1';
import Icon from '../../Icon/Icon';
import Text from '../../Text/Text';
import TitleH3 from '../../TitleH3/TitleH3';
import TitleH4 from '../../TitleH4/TitleH4';
import db from '../../../db/main_db.json';
import './Calculation.scss'

const Calculation = () => {
	return (
		<section className="calculation">
			<div className="container">
				<div className="wrap">
					<div className="left">
						<TitleH1 text={db.tariffs.title}/>
						<p className="desc">{db.tariffs.desc}</p>
						<ul>
							<li>{db.tariffs.text1}</li>
							<li>{db.tariffs.text2}</li>
							<li>{db.tariffs.text3}</li>
							<li>{db.tariffs.text4}</li>
							<li>{db.tariffs.text5}</li>
							<li>{db.tariffs.text6}</li>
						</ul>
					</div>
					<div className="right">
						<Icon icon={'icon-uah uah-xl a-center'}/>
						<Icon icon={'icon-uah uah-md'}/>
						<Icon icon={'icon-uah uah-s'}/>
						<Text classes={'kvt-xl'} text={'кBт'}/>
						<Text classes={'kvt-md'} text={'кBт'}/>
					</div>
				</div>

				<div className="price">
					<div className="lt">
						<Icon icon={'icon-uah uah-lt'}/>
					</div>
					<div className="rt">
						<Text classes={'kvt-m'} text={'кBт'}/>
						<TitleH3 text={db.tariffs.title2}/>
						<div className="box">
							<TitleH4 text={db.tariffs.title3}/>
							<p>{db.tariffs.title3desc}</p>
						</div>
					</div>
				</div>

				<div className="fact-price">
					<TitleH3 text={db.tariffs.title4}/>
					<div className="boxes">
						<div className="box">
							<TitleH4 text={db.tariffs.title5}/>
							<p>{db.tariffs.title5desc}</p>
						</div>
						<div className="box">
							<TitleH4 text={db.tariffs.title6}/>
							<p>{db.tariffs.title6desc}</p>
						</div>
						<div className="box">
							<TitleH4 text={db.tariffs.title7}/>
							<p>{db.tariffs.title7desc}</p>
						</div>
					</div>
					<Icon icon={'icon-uah uah-xl'}/>
					<Icon icon={'icon-uah uah-md'}/>
					<Text classes={'kvt-m'} text={'кBт'}/>
					<Text classes={'kvt-s'} text={'кBт'}/>
				</div>
			</div>
		</section>
	);
};

export default Calculation;
