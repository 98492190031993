import React from 'react';
import db from '../../../db/main_db.json';
import Number from '../../Number/Number';
import './Numbers.scss'
import TitleH2 from '../../TitleH2/TitleH2';

const Numbers = () => {
	return (
		<section className="numbers">
			<div className="container">
				<TitleH2 text={db.numbers.title} />
				<ul>
					<Number num={db.numbers.number1} text={db.numbers.text1}/>
					<Number num={db.numbers.number2} text={db.numbers.text2}/>
					<Number num={db.numbers.number3} text={db.numbers.text3}/>
				</ul>
			</div>
		</section>
	);
};

export default Numbers;
