import React from 'react';
import './TitleH2.scss'

const TitleH2 = (props) => {
	return (
		<h2>{props.text}</h2>
	);
};

export default TitleH2;
