import React from 'react';
import Picture from '../../Picture/Picture';
import TitleH2 from '../../TitleH2/TitleH2';
import LoadLink from '../../Btns/LoadLink';
import db from '../../../db/main_db.json';
import images from '../../../db/images_db.json';
import './Example.scss'

const Example = () => {
	return (
		<section className="example">
			<div className="container">
				<div className="wrap">
					<div className="left">
						<Picture src={images.client.example}/>
					</div>
					<div className="right">
						<TitleH2 text={db.example.title}/>
						<p className="desc">{db.example.desc}</p>
						<LoadLink label={db.example.btn} icon={'icon-pdf'} link={db.example.url}/>
					</div>
				</div>
			</div>
		</section>
	);
};

export default Example;
