import React from 'react';
import TitleH4 from '../../TitleH4/TitleH4';
import Icon from '../../Icon/Icon';
import Picture from '../../Picture/Picture';
import TitleH2 from '../../TitleH2/TitleH2';
import images from '../../../db/images_db.json'
import db from '../../../db/main_db.json';
import './Control.scss'

const Control = () => {
	return (
		<section className="control">
			<div className="container">
				<div className="wrap">
					<div className="left">
						<TitleH2 text={db.control.title}/>
						<p>{db.control.desc}</p>
						<ul>
							{db.control.controlList.map(item => (
								<li key={item.id}>
									<a href={item.url} target="_blank" rel="noreferrer">
										<div className="digital">
											<Icon icon={'icon-lightning a-center'}/>
										</div>
										<TitleH4 text={item.text}/>
									</a>
								</li>
							))}
						</ul>
					</div>
					<div className="right">
						<Picture src={images.contacts.control}/>
					</div>
				</div>
			</div>
		</section>
	);
};

export default Control;
