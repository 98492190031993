import React from 'react';
import Icon from '../../components/Icon/Icon';
import TitleH1 from '../../components/TitleH1/TitleH1';
import LowItem from './LowItem/LowItem';
import db from '../../db/main_db.json';
import './Low.scss'
import Line from '../../components/Line/Line';

function Low() {
	return (
		<main id="low">
			<section className="box-offer">
				<div className="container">
					<div className="wrap">
						<div className="left">
							<TitleH1 text={db.low.title}/>
							<Line/>
							<p>{db.low.desc}</p>
						</div>
						<div className="right">
							<Icon icon={'icon-trident a-center'}/>
						</div>
					</div>
					<ul>
						{db.low.lowList.map(item => (
							<LowItem key={item.id} {...item}/>
						))}
					</ul>
				</div>
			</section>
		</main>
	)
}

export default Low
