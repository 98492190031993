import React from 'react';
import Video from '../../components/Video/Video';
import TitleH2 from '../../components/TitleH2/TitleH2';
import movie from '../../video/cabinet_video.mp4';
import db from '../../db/main_db.json';
import images from '../../db/images_db.json';
import './Cabinet.scss'

const Cabinet = () => {
	return (
		<main id={'cabinet'}>
			<div className="container">
				<TitleH2 text={db.header.cabinet}/>
				<Video autoplay={true} poster={images.main.offer} muted={true} controls={true} link={movie}/>
			</div>
		</main>
	);
};

export default Cabinet;
