import React, {useEffect, useState} from 'react';
import {animateScroll as scroll, Link} from 'react-scroll';
import Icon from '../Icon/Icon';
import './UpArrow.scss'

const UpArrow = () => {
	const [showUpArrow, setShowUpArrow] = useState("up-arrow")

	const listenScrollEvent = (event) => {
		if (window.scrollY > 700) {
			return setShowUpArrow("up-arrow active")
		} else {
			return setShowUpArrow("up-arrow")
		}
	}

	useEffect(() => {
		window.addEventListener('scroll', listenScrollEvent);
		return () =>
			window.removeEventListener('scroll', listenScrollEvent);
	}, []);

	const scrollToTop = () => {
		scroll.scrollToTop();
	};

	return (
		<Link
			activeClass="active"
			className={showUpArrow}
			to="body"
			spy={true}
			smooth={true}
			offset={-70}
			duration={500}
			onClick={scrollToTop}
		><Icon icon="icon-arrow-up"/></Link>
	);
};

export default UpArrow;
