import React from 'react';
import TitleH2 from '../../TitleH2/TitleH2';
import TitleH4 from '../../TitleH4/TitleH4';
import Icon from '../../Icon/Icon';
import db from '../../../db/main_db.json';
import './Timetable.scss'
import Line from '../../Line/Line';

const Timetable = () => {
	return (
		<section className="timetable">
			<div className="container">
				<div className="wrap">
					<div className="left">
						<TitleH2 text={db.timetable.title}/>
						<Line/>
						<p>{db.timetable.desc}</p>
					</div>
					<div className="right">
						<ul>
							<li>
								<TitleH4 text={db.timetable.mt}/>
								<div className="info">
									<Icon icon={'icon-timetable'}/>
									<div className="box">
										<span>{db.timetable.jobTime}</span>
										<b>{db.timetable.jobHours}</b>
									</div>
								</div>
							</li>
							<li>
								<TitleH4 text={db.timetable.f}/>
								<div className="info">
									<Icon icon={'icon-timetable'}/>
									<div className="box">
										<span>{db.timetable.jobTime}</span>
										<b>{db.timetable.jobHours2}</b>
									</div>
								</div>
							</li>
							<li>
								<div className="info">
									<Icon icon={'icon-time'}/>
									<div className="box">
										<span>{db.timetable.lunchTime}</span>
										<b>{db.timetable.lunchHours}</b>
									</div>
								</div>
							</li>
							<li>
								<div className="info">
									<Icon icon={'icon-time'}/>
									<div className="box">
										<span>{db.timetable.lunchTime}</span>
										<b>{db.timetable.lunchHours}</b>
									</div>
								</div>
							</li>
							<li>
								<TitleH4 text={db.timetable.weekend}/>
								<div className="info">
									<Icon icon={'icon-weekend'}/>
									<div className="box">
										<b>{db.timetable.weekendTime}</b>
									</div>
								</div>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</section>
	);
};

export default Timetable;
