import React from 'react';
import './Line.scss'

const Line = () => {
	return (
		<div className="line"/>
	);
};

export default Line;
