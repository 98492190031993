import React from 'react';
import Offer from '../../components/_sections/Offer/Offer';
import Means from '../../components/_sections/Means/Means';
import Timetable from '../../components/_sections/Timetable/Timetable';
import Dispute from '../../components/_sections/Dispute/Dispute';
import Control from '../../components/_sections/Control/Control';
import images from '../../db/images_db.json';
import db from '../../db/main_db.json';
import './Contacts.scss'


function Contacts(props) {
	return (
		<main id="contacts">
			<Offer bg={images.contacts.offer} title1={db.offerContacts.title} title2={db.offerContacts.title2}
			       subtitle={db.offerContacts.subtitle} btn={db.offerContacts.btn} click={() => props.setActive(true)}/>
			<Means/>
			<Timetable/>
			<Dispute/>
			<Offer bg={images.contacts.offer2} title1={db.offerContacts.title} title2={db.offerContacts.title3}
			       subtitle={db.offerContacts.subtitle2} btn={db.offerContacts.btn} click={() => props.setActive(true)}/>
			<Control/>
		</main>
	)
}

export default Contacts
