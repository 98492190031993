import React from 'react';
import Picture from '../../components/Picture/Picture';
import TitleH1 from '../../components/TitleH1/TitleH1';
import ReportsItem from './ReportsItem/ReportsItem';
import Line from '../../components/Line/Line';
import LogoColor from '../../components/Logo/logo-big.png';
import db from '../../db/main_db.json';
import './Reports.scss'

function Reports() {
	return (
		<main id="zvit">
			<section className="box-offer">
				<div className="container">
					<div className="wrap">
						<div className="left">
							<TitleH1 text={db.reports.title}/>
							<Line/>
							<p>{db.reports.desc}</p>
						</div>
						<div className="right">
							<Picture src={LogoColor}/>
						</div>
					</div>
					<ul>
						{db.reports.reportsList.map(item => (
							<ReportsItem key={item.id} {...item}/>
						))}
					</ul>
				</div>
			</section>
		</main>
	)
}

export default Reports
