import React from 'react';
import TextBox from '../../TextBox/TextBox';
import TitleH2 from '../../TitleH2/TitleH2';
import images from '../../../db/images_db.json';
import db from '../../../db/main_db.json';
import './WhyWe.scss'

function WhyWe() {
	return (
		<section className="why-we">
			<div className="container">
				<div className="wrap">
					<div className="left">
						<div className="picture">
							<img src={images.main.whywe} alt="whywe-bg"/>
						</div>
					</div>
					<div className="right">
						<TitleH2 text={db.whywe.title} />
						<div className="wrapper">
							<div className="lt">
								<TextBox title={db.whywe.subtitle1} text={db.whywe.text1}/>
								<TextBox title={db.whywe.subtitle2} text={db.whywe.text2}/>
								<TextBox title={db.whywe.subtitle3} text={db.whywe.text3}/>
							</div>
							<div className="rt">
								<TextBox title={db.whywe.subtitle4} text={db.whywe.text4}/>
								<TextBox title={db.whywe.subtitle5} text={db.whywe.text5}/>
								<TextBox title={db.whywe.subtitle6} text={db.whywe.text6}/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
}

export default WhyWe;
