import React from 'react';
import MyLink from '../../Btns/MyLink';
import TitleH1 from '../../TitleH1/TitleH1';
import Btn from '../../Btns/Btn';
import './Offer.scss'

function Offer(props) {
	return (
		<section className="offer">
			<div className="offer-bg">
				<img src={props.bg} alt="offer-bg"/>
			</div>
			<div className="container">
				<div className="title">
					<TitleH1 text={props.title1} strong={props.title2}/>
					{(props.subtitle) ? <p className="desc">{props.subtitle}</p> : ''}
					{(props.link) ? <MyLink icon={'icon-lightning'} label={props.btn} link={props.link}/> :
						<Btn icon={'icon-lightning'} label={props.btn} click={props.click}/>}
				</div>
			</div>
		</section>
	);
}

export default Offer;
